@import '../../styles/index';

.base {
  width: 100%;
  height: 1px;
  border: none;

  &.none {
    margin-top: 0;
    margin-bottom: 0;
  }

  &.default {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
}

.gray {
  background-color: #d9dadb;
}

.turquoise {
  background-color: transparentize($color: $color-turquoise--100, $amount: 0.8);
}

.orange {
  background-color: transparentize($color: $color-orange--100, $amount: 0.8);
}

.surrogat {
  background-color: $color-surrogate--10;
}
